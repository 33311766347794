import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import {
  BumpOfferData,
  ProductData,
  ProductPrice
} from '../../utils/productPrice';
import useLocalStorage from '../../hooks/useLocalStorage';
import * as S from './FunnelText.styles';

const FunnelText = props => {
  const {
    text,
    chewsPerTub,
    savingsAddon,
    subSavingsAddon,
    otpSavingsAddon,
    margin
  } = props;

  const context = useContext(FunnelContext);

  const {
    currency,
    currentTubIndex,
    currentCategory,
    currentProduct,
    onetime,
    subscription,
    extraObjects
  } = context;

  const [extraObjects2] = useLocalStorage('extraObjects', {});

  const InnerHTML = () => {
    let currentText = text;

    const currencySymbol = currency?.symbol || '$';
    const discountedPrice = ProductPrice({
      type: `discounted_price`,
      currentCategory: currentCategory,
      currentProduct: currentProduct
    });
    const price = ProductPrice({
      type: `price`,
      currentCategory: currentCategory,
      currentProduct: currentProduct
    });
    let savings = ProductPrice({
      type: `savings`,
      currentCategory: currentCategory,
      currentProduct: currentProduct
    });
    savings = parseFloat(savings) + parseFloat(savingsAddon || 0);

    currentText = currentText?.replace(/\[currency-symbol\]/gm, currencySymbol);
    currentText = currentText?.replace(/\[price\]/gm, price);
    currentText = currentText?.replace(
      /\[discounted-price\]/gm,
      discountedPrice
    );
    currentText = currentText?.replace(/\[savings\]/gm, savings.toFixed(2));

    const [productData] = ProductData({
      currentCategory: currentCategory,
      currentProduct: currentProduct
    });

    currentText = currentText?.replace(
      /\[quantity\]/gm,
      productData?.checkoutData.quantity
    );
    currentText = currentText?.replace(
      /\[image\]/gm,
      productData?.checkoutData.image
    );
    currentText = currentText?.replace(
      /\[perceived_rrp\]/gm,
      productData?.checkoutData.perceived_rrp
    );
    currentText = currentText?.replace(
      /\[discount_percentage\]/gm,
      productData?.discount_percentage
    );
    currentText = currentText?.replace(
      /\[display_title\]/gm,
      productData?.display_title
    );

    const pricePerChew = (
      price /
      ((chewsPerTub ?? 30) * productData?.checkoutData.quantity)
    ).toFixed(2);
    currentText = currentText?.replace(/\[price-per-chew\]/gm, pricePerChew);
    const discountedPricePerChew = (
      discountedPrice /
      ((chewsPerTub ?? 30) * productData?.checkoutData.quantity)
    ).toFixed(2);
    currentText = currentText?.replace(
      /\[discounted-price-per-chew\]/gm,
      discountedPricePerChew
    );

    const pricePerTub = (price / productData?.checkoutData.quantity).toFixed(2);
    currentText = currentText?.replace(/\[price-per-tub\]/gm, pricePerTub);
    const discountedPricePerTub = (
      discountedPrice / productData?.checkoutData.quantity
    ).toFixed(2);
    currentText = currentText?.replace(
      /\[discounted-price-per-tub\]/gm,
      discountedPricePerTub
    );

    const [bumpOfferData] = BumpOfferData({
      currentCategory: currentCategory
    });

    currentText = currentText?.replace(
      /\[bump-discounted-price\]/gm,
      bumpOfferData.checkoutData.discounted_price
    );
    currentText = currentText?.replace(
      /\[bump-price\]/gm,
      bumpOfferData.checkoutData.price
    );
    currentText = currentText?.replace(
      /\[bump-image\]/gm,
      bumpOfferData.checkoutData.image
    );

    onetime?.products.map((product, index) => {
      const OTPdiscountedPrice = ProductPrice({
        type: `discounted_price`,
        currentCategory: 'onetime',
        currentProduct: `onetime_${index}`
      });
      const OTPprice = ProductPrice({
        type: `price`,
        currentCategory: 'onetime',
        currentProduct: `onetime_${index}`
      });
      let OTPsavings = ProductPrice({
        type: `savings`,
        currentCategory: 'onetime',
        currentProduct: `onetime_${index}`
      });
      OTPsavings = parseFloat(OTPsavings) + parseFloat(otpSavingsAddon || 0);

      if (index === currentTubIndex) {
        currentText = currentText?.replace(
          `[otp-discounted-price]`,
          OTPdiscountedPrice
        );
        currentText = currentText?.replace(`[otp-price]`, OTPprice);
        currentText = currentText?.replace(
          `[otp-savings]`,
          OTPsavings.toFixed(2)
        );
      }

      currentText = currentText?.replace(
        `[otp-discounted-price-${index}]`,
        OTPdiscountedPrice
      );
      currentText = currentText?.replace(`[otp-price-${index}]`, OTPprice);
      currentText = currentText?.replace(
        `[otp-savings-${index}]`,
        OTPsavings.toFixed(2)
      );
      return null;
    });

    subscription?.products.map((product, index) => {
      const SUBdiscountedPrice = ProductPrice({
        type: `discounted_price`,
        currentCategory: 'subscriptions',
        currentProduct: `subscription_${index}`
      });
      const SUBprice = ProductPrice({
        type: `price`,
        currentCategory: 'subscriptions',
        currentProduct: `subscription_${index}`
      });
      let SUBsavings = ProductPrice({
        type: `savings`,
        currentCategory: 'subscriptions',
        currentProduct: `subscription_${index}`
      });
      SUBsavings = parseFloat(SUBsavings) + parseFloat(subSavingsAddon || 0);

      if (index === currentTubIndex) {
        currentText = currentText?.replace(
          `[sub-discounted-price]`,
          SUBdiscountedPrice
        );
        currentText = currentText?.replace(`[sub-price]`, SUBprice);
        currentText = currentText?.replace(
          `[sub-savings]`,
          SUBsavings.toFixed(2)
        );
      }

      currentText = currentText?.replace(
        `[sub-discounted-price-${index}]`,
        SUBdiscountedPrice
      );
      currentText = currentText?.replace(`[sub-price-${index}]`, SUBprice);
      currentText = currentText?.replace(
        `[sub-savings-${index}]`,
        SUBsavings.toFixed(2)
      );
      return null;
    });

    currentText = currentText?.replace(
      `[extra-schedule_text]`,
      extraObjects?.schedule_text ?? 'every month'
    );
    currentText = currentText?.replace(
      `[extra2-schedule_text]`,
      extraObjects2?.schedule_text ?? 'every month'
    );

    currentText = currentText?.replace(
      `[extra-current-category]`,
      extraObjects?.currentCategory === 'subscriptions'
        ? 'Subscription'
        : 'One time'
    );

    for (let [key, value] of Object.entries(extraObjects)) {
      currentText = currentText?.replace(`[extra-${key}]`, value ?? '');
    }

    extraObjects2 &&
      Object.keys(extraObjects2).forEach(key => {
        currentText = currentText?.replace(
          `[extra-${key}]`,
          extraObjects2[key] ?? ''
        );
      });

    return {
      __html: currentText
    };
  };

  return (
    <S.Container
      {...props.attributes}
      dangerouslySetInnerHTML={InnerHTML()}
      margin={margin}
    />
  );
};

export default FunnelText;
